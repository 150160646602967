import React from 'react'
import Layout from '../layout/layout'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'

export default function NotFound({ data }) {
  const posts = data.allMdx.edges
  return (
    <Layout title="404 - Note Not Found 😉">
      <div className="column is-half">
        <h1>❌ Oops, looks like there's nothing here</h1>

        <br />
        <p className="lead">Why dont you try out these latest articles?</p>
        <ul>
          {posts?.map(({ node }) => {
            return (
              <li key={node.fields.slug}>
                <Link to={node.fields?.slug}>{node.fields?.title}</Link>
                {/* <p>{node?.excerpt}</p> */}
              </li>
            )
          })}
        </ul>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      sort: { fields: frontmatter___pubDate, order: DESC }
      limit: 10
      filter: { frontmatter: { pubDate: { ne: null } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            title
          }
          frontmatter {
            pubDate
          }
        }
      }
    }
  }
`
